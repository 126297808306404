import React from "react";
import styles from "./HomePage.module.css";
import {Row} from "../../utils/row/Row";
import icon1 from "./icons/icon1.png";
import icon2 from "./icons/icon2.png";
import icon3 from "./icons/icon3.png";
import icon4 from "./icons/icon4.png";
import {Category, getItemsOnSale, ShortItem} from "../../../api/API";
import {LoadableList} from "../../../utils/loadable";
import {Observer, useLocalObservable} from "mobx-react";
import {CategoryCard} from "./components/category_card/CategoryCard";
import {Stub} from "../../utils/stub/Stub";
import {ItemCard} from "./components/item_card/ItemCard";
import {useHistory} from "react-router-dom";
import {StubCarousel} from "./components/stub_carousel/StubCarousel";
import background1 from "./backgrounds/1.jpg";
import background2 from "./backgrounds/2.jpg";
import background3 from "./backgrounds/3.jpg";

interface HomePageProps {
    categories: LoadableList<Category>;
}

export function HomePage(props: HomePageProps) {
    const state = useLocalObservable(() => ({
        firstItemsOnSale: new LoadableList<ShortItem>(async () => (await getItemsOnSale(0, 5)).items)
    }));

    const history = useHistory();

    props.categories.load().catch(err => console.error(err));
    state.firstItemsOnSale.load().catch(err => console.error(err));

    return (<Observer>{() => (<>
        <div className={styles.stubImageContainer}>
            <StubCarousel className={styles.stubImageBlock}
                          images={[background1, background2, background3]}
                          delay={3000} contents={[<></>, <></>, <></>, <></>]}/>
        </div>
        {/*
            <div className={`${styles.stubImageBlock} ${styles.stubImage1}`}/>
            <div className={`${styles.stubImageBlock} ${styles.stubImage2}`}/>
            <div className={`${styles.stubImageBlock} ${styles.stubImage3}`}/>
        </div>*/}
        <Row>
            <div className={styles.contentBlock}>
                Наша компания - одна из крупных участников московского рынка оптовых поставок охлаждённого мяса и
                мяса глубокой заморозки
                <br/>
                В числе наших клиентов крупные мясоперерабатывающие комбинаты, ведущие торговые сети и супермаркеты
                столицы и регионов, различные, в том числе лечебные и образовательные учреждения.
            </div>
            <div className={styles.contentBlock}>
                <div className={styles.titleBar}>
                    <span className={styles.titleText}>Мы гарантируем</span>
                </div>
                <div className={styles.contentRow}>
                    <div className={styles.warrantyCardColumn}>
                        <div className={styles.iconBlock}>
                            <img src={icon1} alt={"Рука вверх"}/>
                        </div>
                        <div className={styles.warrantyTitleText}>
                            Качество
                        </div>
                        <div className={styles.warrantyText}>
                            Поставки нашей продукции напрямую от производителя по
                            <span className={styles.greenText}> лучшим ценам</span> и без задержек
                        </div>
                    </div>
                    <div className={styles.warrantyCardColumn}>
                        <div className={styles.iconBlock}>
                            <img src={icon2} alt={"Сертификат"}/>
                        </div>
                        <div className={styles.warrantyTitleText}>
                            Безопастность
                        </div>
                        <div className={styles.warrantyText}>
                            Имеется вся необходимая <span
                            className={styles.greenText}> сопутствующая документация</span> и сертификаты на товары
                        </div>
                    </div>
                    <div className={styles.warrantyCardColumn}>
                        <div className={styles.iconBlock}>
                            <img src={icon3} alt={"Овощи"}/>
                        </div>
                        <div className={styles.warrantyTitleText}>
                            Ассортимент
                        </div>
                        <div className={styles.warrantyText}>
                            <span className={styles.greenText}>Широкий ассортимент.</span> Мы анализировать рынок,
                            благодаря этому всегда сможем удовлетворить ваши потребности
                        </div>
                    </div>
                    <div className={styles.warrantyCardColumn}>
                        <div className={styles.iconBlock}>
                            <img src={icon4} alt={"100% гарантия"}/>
                        </div>
                        <div className={styles.warrantyTitleText}>
                            Гарантии
                        </div>
                        <div className={styles.warrantyText}>
                            <span className={styles.greenText}>Более 5 лет на рынке</span> оптовых поставок. Выбирая нас
                            вы выбираете качество и надёжность
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.contentBlock}>
                <div className={styles.titleBar}>
                    <span className={styles.titleText}>Популярные категории</span>
                    <div className={styles.fillerBlock}/>
                    <span>Смотреть все товары &gt;</span>
                    <button onClick={() => history.push('/catalog')} className={styles.downloadButton}>Весь
                        каталог
                    </button>
                </div>
                <div className={styles.contentRow}>
                    {(props.categories.loading ? (Array.from(Array(3).keys()).map(i =>
                            (<Stub width={"100%"} height={"100px"} key={`category-stub-${i}`}/>)))
                        : (props.categories.items.filter(item => item.imageUrl).slice(0, 3).map(category =>
                            (<CategoryCard category={category} key={`category-card-${category.id}`}/>))))
                        .map((card, i) => (
                            <div className={styles.categoryCardColumn} key={`category-column-${i}`}>{card}</div>))}
                </div>
                <div className={styles.contentRow}>
                    {(props.categories.loading ? (Array.from(Array(3).keys()).map(i =>
                            (<Stub width={"100%"} height={"100px"} key={`category-stub-${i + 3}`}/>)))
                        : (props.categories.items.filter(item => item.imageUrl).slice(3, 6).map(category =>
                            (<CategoryCard category={category} key={`category-card-${category.id}`}/>))))
                        .map((card, i) => (
                            <div className={styles.categoryCardColumn} key={`category-column-${i + 3}`}>{card}</div>))}
                </div>
            </div>
            <div className={styles.contentBlock}>
                <div className={styles.titleBar}>
                    <span className={styles.titleText}>Акции %</span>
                    <div className={styles.fillerBlock}/>
                    <span className={styles.link} onClick={() => history.push('/sales')}>Смотреть все акции &gt;</span>
                </div>
                <div className={styles.contentRow}>
                    {(state.firstItemsOnSale.loading ? (Array.from(Array(5).keys()).map(i =>
                            (<Stub width={"100%"} height={"300px"} key={`sale-stub-${i + 3}`}/>)))
                        : (state.firstItemsOnSale.items.slice(0, 5).map(item =>
                            (<ItemCard item={item} key={`sale-card-${item.id}`}/>))))
                        .map((card, i) => (
                            <div className={styles.saleCardColumn} key={`sale-column-${i + 3}`}>{card}</div>))}
                </div>
            </div>
        </Row>
    </>)}</Observer>);
}
