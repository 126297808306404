import React from "react";
import styles from "./Price.module.css";

interface PriceProps {
    price: number;
    discount?: number;
    unit?: string;
    showDiscount?: boolean;
}

export function Price(props: PriceProps) {
    if (props.discount !== undefined) {
        if (props.showDiscount ?? true) {
            return (<><span className={styles.newPrice}>{Math.round(props.price - (props.discount / 100 * props.price)) / 100} RUB
                {props.unit ? `/${props.unit}` : <></>}</span>
                &nbsp;<span className={styles.oldPrice}>{props.price / 100} RUB
                    {props.unit ? `/${props.unit}` : <></>}</span></>);
        }

        return (<>{Math.round(props.price - (props.discount / 100 * props.price)) / 100} RUB
            {props.unit ? `/${props.unit}` : <></>}</>);
    }
    return (<>{props.price / 100} RUB{props.unit ? `/${props.unit}` : <></>}</>);
}