import React from "react";
import styles from "./NotFoundPage.module.css";
import {Row} from "../../utils/row/Row";
import background from "./404_background.png";

export function NotFoundPage() {
    return (<><Row>
        <div className={styles.background404Block}>
            <img className={styles.imageBlock} src={background} alt={"404"}/>
        </div>
    </Row></>);
}
