import {Page} from "../../page/Page";
import React from "react";
import {Route, Router, Switch, useLocation} from "react-router-dom";
import {HomePage} from "../../pages/home/HomePage";
import {ProductPage} from "../../pages/product/ProductPage";
import {CatalogPage} from "../../pages/catalog/CatalogPage";
import {NotFoundPage} from "../../pages/not_found/NotFoundPage";
import {AboutPage} from "../../pages/about/AboutPage";
import {PricePage} from "../../pages/price/PricePage";
import {ContactsPage} from "../../pages/contacts/ContactsPage";
import {PolicyPage} from "../../pages/policy/PolicyPage";
import {LogisticsPage} from "../../pages/logistics/LogisticsPage";
import {createBrowserHistory} from 'history';
import {APIStateStorage} from "../../../api/APIStateStorage";
import {ScrollToTop} from "../../utils/scroll/ScrollToTop";
import {Cart} from "../../../cart/Cart";
import {NewsPage} from "../../pages/news/NewsPage";
import {PostPage} from "../../pages/post/PostPage";

const history = createBrowserHistory();

export interface AppRouterProps {
    stateStorage: APIStateStorage;
    cart: Cart;
}

function InnerRouter(props: AppRouterProps) {
    const location = useLocation();
    let category: string | null | undefined;
    if (location.pathname.startsWith("/catalog/")) {
        category = location.pathname.substr("/catalog/".length);
    } else if (location.pathname === "/catalog") {
        category = null;
    }
    return (<>
        <ScrollToTop/>
        <Page categories={props.stateStorage.categories} currentCategory={category} cart={props.cart}>
            <Switch>
                <Route exact={true} path="/policy">
                    <PolicyPage/>
                </Route>
                <Route exact={true} path="/contacts">
                    <ContactsPage/>
                </Route>
                <Route exact={true} path="/logistics">
                    <LogisticsPage/>
                </Route>
                <Route exact={true} path="/about">
                    <AboutPage/>
                </Route>
                <Route exact={true} path="/price">
                    <PricePage/>
                </Route>
                <Route exact={true} path="/news">
                    <NewsPage/>
                </Route>
                <Route exact={true} path="/post/:postId"
                       render={routeProps => (<PostPage postId={routeProps.match.params.postId}/>)}/>
                <Route exact={true} path="/product/:productId"
                       render={routeProps => (<ProductPage productId={routeProps.match.params.productId}
                                                           items={props.stateStorage.items}
                                                           categories={props.stateStorage.categories}
                                                           cart={props.cart}/>)}/>
                <Route exact={true} path="/sales">
                    <CatalogPage type={"sales"} categories={props.stateStorage.categories} cart={props.cart}/>
                </Route>
                <Route exact={true} path="/catalog">
                    <CatalogPage type={"all"} categories={props.stateStorage.categories} cart={props.cart}/>
                </Route>
                <Route exact={true} path="/catalog/:categoryId"
                       render={routeProps => (
                           <CatalogPage type={"category"} categories={props.stateStorage.categories}
                                        categoryId={routeProps.match.params.categoryId} cart={props.cart}/>)}>
                </Route>
                <Route exact={true} path="/search/:search"
                       render={routeProps => (
                           <CatalogPage type={"search"} categories={props.stateStorage.categories}
                                        search={routeProps.match.params.search} cart={props.cart}/>)}>
                </Route>
                <Route exact={true} path="/">
                    <HomePage categories={props.stateStorage.categories}/>
                </Route>
                <Route>
                    <NotFoundPage/>
                </Route>
            </Switch>
        </Page>
    </>);
}

export function AppRouter(props: AppRouterProps) {
    return (<>
        <Router history={history}>
            <InnerRouter {...props}/>
        </Router>
    </>);
}
