export const priceList = [
  {
    title: 'Птица',
    price: [
      {
        name: 'ЦБ охл., СНОВ, 1 кат.',
        country: ' Беларусь ',
        typePack: ' Кор.',
        availability: ' Под заказ ',
        price: ' ожидается ',
      },
      {
        name: 'ЦБ охл., Скидель, 1 кат.',
        country: ' Беларусь ',
        typePack: ' Кор.',
        availability: ' Под заказ ',
        price: ' ожидается ',
      },

      {
        name: ' Окорочок кур. зам., на подложке, Скидель',
        country: ' Беларусь ',
        typePack: ' Кор.',
        availability: 'склад МО',
        price: ' 155 руб.',
      },

      {
        name: ' Печень кур. зам., пакет 1 кг., Скидель',
        country: ' Беларусь ',
        typePack: ' Кор. 12 кг.',
        availability: ' склад МО ',
        price: ' 120 руб.',
      },

      {
        name: ' Сердце кур. зам., пакет 1 кг., Скидель',
        country: ' Беларусь ',
        typePack: ' Кор. 12 кг.',
        availability: ' склад МО ',
        price: ' 210 руб.',
      },
      {
        name: ' Желудки кур. зам., пакет 1 кг., Скидель ',
        country: ' Беларусь ',
        typePack: ' Кор. 12 кг.',
        availability: ' склад МО ',
        price: ' 110 руб.',
      },
    ],
  },
  {
    title: 'Говядина',
    price: [
      {
        name: ' Отруб пояснично-подвздошная мышца (вырезка) говяжья, Бык замороженный ',
        country: ' Беларусь ',
        typePack: ' Кор..',
        availability: ' склад МО ',
        price: ' 760 руб.',
      },

      {
        name: ' Отруб шейный бескостный говяжий замороженный, Бык ',
        country: ' Беларусь ',
        typePack: ' Кор.',
        availability: ' склад МО ',
        price: ' 410 руб.',
      },

      {
        name: ' Отруб лопаточный без голяшки бескостный говяжий замороженный, Бык ',
        country: ' Беларусь ',
        typePack: ' Кор.',
        availability: ' склад МО ',
        price: ' 425 руб.',
      },

      {
        name: ' Тазобедренный отруб без голяшки бескостный говяжий наружный (подбедерок) замороженный, Бык ',
        country: ' Беларусь ',
        typePack: ' Кор.',
        availability: ' склад МО ',
        price: ' 465 руб.',
      },

      {
        name: ' Тазобедренный отруб без голяшки бескостный говяжий внутренний(огузок) замороженный, Бык ',
        country: ' Беларусь ',
        typePack: ' Кор.',
        availability: ' склад МО ',
        price: ' 470 руб.',
      },

      {
        name: ' Тазобедренный отруб без голяшки бескостный говяжий боковой (оковалок) замороженный, Бык ',
        country: ' Беларусь ',
        typePack: ' Кор.',
        availability: ' склад МО ',
        price: ' 475 руб.',
      },

      {
        name: ' Тазобедренный отруб без голяшки бескостный говяжий наружный (глазной мускул) замороженный, Бык ',
        country: ' Беларусь ',
        typePack: ' Кор.',
        availability: ' склад МО ',
        price: ' 530 руб.',
      },

      {
        name: ' Отруб спинно-поясничный бескостный говяжий замороженный (толстый край), Бык ',
        country: ' Беларусь ',
        typePack: ' Кор.',
        availability: ' склад МО ',
        price: ' 460 руб.',
      },
      {
        name: ' Отруб говяжий бескостный задняя голяшка бескостная замороженная, Бык ',
        country: ' Беларусь ',
        typePack: ' Кор.',
        availability: ' склад МО ',
        price: ' 350 руб.',
      },

      {
        name: ' Отруб говяжий бескостный передняя голяшка бескостная замороженная, Бык ',
        country: ' Беларусь ',
        typePack: ' Кор.',
        availability: ' склад МО ',
        price: ' 350 руб.',
      },

      {
        name: " Полуфабрикат мясной натуральный крупно кусковой бескостный из говядины 'говядина односортная'. Замороженный, Бык ",
        country: ' Беларусь ',
        typePack: ' Кор.',
        availability: ' склад МО ',
        price: ' 350 руб.',
      },

      {
        name: ' Тазобедренный отруб без глазного мускула бескостный говяжийзамороженный ',
        country: ' Россия ',
        typePack: ' Кор.',
        availability: ' склад МО ',
        price: ' 465 руб.',
      },

      {
        name: ' Глазной мускул бескостный говяжийзамороженный ',
        country: ' Россия ',
        typePack: ' Кор.',
        availability: ' склад МО ',
        price: ' 505 руб.',
      },
      {
        name: ' Отруб лопаточный бескостный говяжий замороженный ',
        country: ' Россия ',
        typePack: ' Кор.',
        availability: ' склад МО ',
        price: ' 415 руб.',
      },

      {
        name: ' Отруб шейный бескостный говяжий замороженный ',
        country: ' Россия ',
        typePack: ' Кор.',
        availability: ' склад МО ',
        price: ' 400 руб.',
      },

      {
        name: ' Отруб говяжий бескостный голяшка бескостная замороженная ',
        country: ' Россия ',
        typePack: ' Кор.',
        availability: ' склад МО ',
        price: ' 325 руб.',
      },
      {
        name: ' Жилка мягкая ',
        country: ' Россия ',
        typePack: ' Кор.',
        availability: ' склад МО ',
        price: ' 150 руб.',
      },

      {
        name: ' Жилка становая ',
        country: ' Россия ',
        typePack: ' Кор.',
        availability: ' склад МО ',
        price: ' 120 руб.',
      },

      {
        name: ' Жир корпусной ',
        country: ' Россия ',
        typePack: ' Кор.',
        availability: ' склад МО ',
        price: ' 110 руб.',
      },
      {
        name: ' Высший сорт ',
        country: ' Россия ',
        typePack: ' Кор.',
        availability: ' склад МО ',
        price: ' 465 руб.',
      },

      {
        name: ' Первый сорт',
        country: ' Россия ',
        typePack: ' Кор.',
        availability: ' склад МО ',
        price: ' 365 руб.',
      },
      {
        name: ' Второй сорт',
        country: ' Россия ',
        typePack: ' Кор.',
        availability: ' склад МО ',
        price: ' 300 руб.',
      },
    ],
  },
];
