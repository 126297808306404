import React from "react";
import {Row} from "../../utils/row/Row";
import styles from "./PolicyPage.module.css";

export function PolicyPage() {
    return (<><Row>
        <div className={styles.policyBlock}>
            <div className={styles.headerText}>Политика конфиденциальности</div>
            <div className={styles.textBlock}>
                <div className={styles.titleText}>Признание условий использования</div>
                <div className={styles.text}>
                    Компания «РУССКИЕ ПРОДУКТЫ» заботится о конфиденциальности ваших данных. Защита ваших персональных данных —
                    один из
                    наших ключевых приоритетов. Для этих целей была разработана Политика конфиденциальности (далее
                    “Политика”),
                    которая включающая правила сбора, использования, раскрытия, передачи и хранения данных о посетителях
                    веб-сайта www.russproduct.ru.
                    Компания «РУССКИЕ ПРОДУКТЫ» оставляет за собой право вносить изменения в настоящую Политику без
                    предварительного
                    уведомления. Актуальная версия Политики размещена на сайте www.russproduct.ru.
                    Пожалуйста, внимательно ознакомьтесь с положениями настоящей Политики конфиденциальности.
                    Если вы не согласны с данной Политикой конфиденциальности, мы просим вас воздержаться от пользования
                    нашими
                    услугами.
                </div>
            </div>
            <div className={styles.textBlock}>
                <div className={styles.titleText}>Использование веб-сайта</div>
                <div className={styles.text}>
                    Веб-сайт www.russproduct.ru может может использоваться только в законных личных некоммерческих целях.
                    Вы не можете использовать средства извлечения информации, роботов и/или иные средства сбора и
                    извлечения
                    данных из веб-сайта www.russproduct.ru, а также воспроизводить, копировать, изменять, публиковать или
                    как-то
                    иначе распространять информационное наполнение данного веб-сайта.
                </div>
            </div>
            <div className={styles.textBlock}>
                <div className={styles.titleText}>Сбор и использование личной информации</div>
                <div className={styles.text}>При посещении веб-сайта «РУССКИЕ ПРОДУКТЫ», наши веб-сервера автоматически
                    собирают
                    данные об IP-адресе, с
                    которого
                    вы перешли на данный веб-сайт, вашем IP-адресе, типе браузера и операционной системе. Мы
                    рассматриваем
                    эти
                    данные в качестве информации, не являющейся личной.
                    <b>«РУССКИЕ ПРОДУКТЫ» использует ваш IP-адрес для:</b>
                    <ul>
                        <li>Диагностирования проблем с собственными серверами</li>
                        <li>Администрирования собственного веб-сайта (эти данные помогают понять, какие разделы
                            веб-сайта посещаются
                            чаще всего)
                        </li>
                        <li>Отслеживание нарушений настоящей Политики и других правил «РУССКИЕ ПРОДУКТЫ».</li>
                    </ul>
                    Мы не привязываем IP-адреса к личной информации. Это подразумевает, что все посетители веб-сайта
                    www.russproduct.ru отслеживаются, но при сохранении анонимности.
                    Контактная форма на веб-сайте www.russproduct.ru требует заполнения полей Имя, E-mail и Название
                    компании.
                    Мы
                    храним и используем эту информацию для последующей связи с вами. Мы не передаем информацию о вашем
                    E-mail
                    адресе третьим лицам без вашего согласия.
                </div>
            </div>
            <div className={styles.textBlock}>
                <div className={styles.titleText}>Cookie</div>
                <div className={styles.text}>Куки (cookie) — это небольшие текстовые файлы, в которые браузер может
                    записывать данные, полученные от
                    веб-сервера.
                    Когда вы переходите на страницу веб-сайта РУССКИЕ ПРОДУКТЫ, веб-сервер может считывать и собирать
                    информацию,
                    содержащуюся в куках.

                    Эта информация поможет нам лучше понять поведение наших клиентов, узнать, какие разделы веб-сайта
                    являются
                    наиболее востребованными среди наших посетителей. Таким образом мы всегда стремимся улучшить
                    качество
                    подаваемой информации и оказываемых услуг, а также повысить эффективность рекламы.
                    Мы собираем данные автоматически. Чтобы отключить файлы cookie, воспользуйтесь настройками вашего
                    браузера.
                </div>
            </div>
            <div className={styles.textBlock}>
                <div className={styles.titleText}>Безопасность</div>
                <div className={styles.text}>Для хранения вашей личной информации «РУССКИЕ ПРОДУКТЫ» использует специальные
                    средства технической защиты.
                    Наши
                    меры безопасности постоянно совершенствуются по мере развития новых технологий.
                    Доступ к вашей личной информации закрыт также для большинства сотрудников «РУССКИЕ ПРОДУКТЫ» (включая тех,
                    кто
                    с
                    вами может контактировать).
                    Наш веб-сайт может содержать гиперссылки на другие интернет-ресурсы, предоставляемые третьими
                    лицами,
                    информацию и безопасность на которых мы не контролируем. После того, как вы покинули веб-сайт
                    www.russproduct.ru, мы не несем ответственность за защиту любой вашей информации. Вы должны проявлять
                    осторожность и ознакомиться с политикой конфиденциальность сайта, который вы посещаете.
                </div>
            </div>
            <div className={styles.textBlock}>
                <div className={styles.titleText}>Раскрытие персональной информации третьим лицам</div>
                <div className={styles.text}>Компания «РУССКИЕ ПРОДУКТЫ» может быть обязана раскрыть вашу личную информацию в
                    соответствии с
                    законодательством,
                    требованиями уполномоченных государственных органов, судебными разбирательствами. Также мы можем
                    раскрыть
                    вашу информацию, если это будет необходимо в целях национальной безопасности или в иных общественно
                    важных
                    целях. При наступлении обстоятельств, когда требуется раскрытие личной информации, мы передаем
                    личные
                    ваши
                    данные только в том объеме, который требуется по закону или является необходимым для предотвращения,
                    выявления и/или пресечения преступных действий, или если мы иным образом юридически обязаны передать
                    их.
                </div>
            </div>
            <div className={styles.textBlock}>
                <div className={styles.titleText}>Контактная информация</div>
                <div className={styles.text}>По всем вопросам в отношении данной Политики, обращайтесь, пожалуйста, в
                    Службу
                    поддержки пользователей
                    через веб-сайт www.russproduct.ru или на электронный адрес info@russproduct.ru.
                </div>
            </div>
        </div>
    </Row></>);
}
