import styles from "./NewsPage.module.css";
import React from "react";
import {Row} from "../../utils/row/Row";
import {Observer, useLocalObservable} from "mobx-react";
import {Stub} from "../../utils/stub/Stub";
import {useHistory} from "react-router-dom";
import {getNews, ShortNewsPost} from "../../../api/API";

const itemsPerPage = 6;

export function NewsPage() {
    const history = useHistory();
    const state = useLocalObservable(() => ({
        loadingItems: false,
        totalItems: 0,
        items: [] as ShortNewsPost[]
    }));

    if (!state.loadingItems && state.items.length === 0) {
        state.loadingItems = true;
        getNews(state.items.length, itemsPerPage).then(itemsResult => {
            state.totalItems = itemsResult.totalItems;
            state.items.push(...itemsResult.items);
            state.loadingItems = false;
        }).catch(err => console.error(err));
    }

    return (<Observer>{() => (<>
        <Row>
            <div className={styles.headerMarginBlock}/>
            {Array.from(new Array(Math.ceil(state.items.length / 3)).keys()).map(i => state.items.slice(i * 3, i * 3 + 3)).map(slice =>
                (<div className={styles.contentRow}>{slice.map(item => (
                    <div className={styles.itemCardColumn}>
                        <div className={styles.itemCardImage} style={{backgroundImage: `url("${item.imageUrl}")`}}
                             onClick={() => history.push(`/post/${item.id}`)}/>
                        <div className={styles.itemCardInfoBlock}>
                            <div className={styles.titleBlock}
                                 onClick={() => history.push(`/post/${item.id}`)}>{item.title}</div>
                            <div className={styles.previewTextBlock}>{item.previewText}</div>
                        </div>
                    </div>
                ))}</div>))}
            {state.loadingItems ? (Array.from(new Array(Math.ceil(itemsPerPage / 3)).keys()).map(() =>
                (<div className={styles.contentRow}>{Array.from(new Array(3).keys()).map(() =>
                    (<div className={styles.itemCardColumn}>
                        <Stub width={"100%"} height={"250px"}/>
                    </div>))}</div>))) : (<></>)}
            {state.loadingItems || state.totalItems <= state.items.length ? (<></>) : (
                <div className={styles.loadMoreRow}><span className={styles.loadMoreText} onClick={() => {
                    state.loadingItems = true;
                    getNews(state.items.length, itemsPerPage).then(itemsResult => {
                        state.totalItems = itemsResult.totalItems;
                        state.items.push(...itemsResult.items);
                        state.loadingItems = false;
                    }).catch(err => console.error(err));
                }}>Показать больше</span></div>)}
            <div className={styles.footerMarginBlock}/>
        </Row>
    </>)}</Observer>);
}
