import styles from "./CategoryCard.module.css";
import React from "react";
import {Category} from "../../../../../api/API";
import { useHistory } from "react-router-dom";

interface CategoryCardProps {
    category: Category;
}

export function CategoryCard(props: CategoryCardProps) {
    const history = useHistory();
    return (<div className={styles.categoryCard} style={{backgroundImage: `url(${props.category.imageUrl})`}}
    onClick={() => history.push(`/catalog/${props.category.id}`)}>
            {props.category.name}
        </div>);
}
