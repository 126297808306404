import {Row} from "../../utils/row/Row";
import React from "react";
import styles from "./ProductPage.module.css";
import {LoadableList, LoadableMap} from "../../../utils/loadable";
import {Category, Item} from "../../../api/API";
import {Observer} from "mobx-react";
import {Stub} from "../../utils/stub/Stub";
import {Link} from "react-router-dom";
import {Price} from "../../utils/price/Price";
import {Cart} from "../../../cart/Cart";

interface ProductPageProps {
    productId: string;
    categories: LoadableList<Category>;
    items: LoadableMap<Item>;
    cart: Cart;
}

export function ProductPage(props: ProductPageProps) {
    props.categories.load().catch(err => console.error(err));
    props.items.load(props.productId).catch(err => console.error(err));

    return (<Observer>{() => {
        const loading = props.items.isLoading(props.productId) || props.categories.loading;
        const item = props.items.getItem(props.productId);
        return (<>
            <Row>
                {loading ? (<>
                    <div className={styles.breadcrumbsBlock}>
                        <Stub width={"50%"} height={"20px"}/>
                    </div>
                    <div className={styles.flexBlock}>
                        <Stub additionalClassName={styles.productImageStub}/>
                        <div className={styles.productInfoBlock}>
                            <Stub additionalClassName={styles.productNameStub} />
                            <Stub additionalClassName={styles.productDescriptionStub}/>
                            <div className={styles.productPriceAndBuyBlock}>
                                <Stub additionalClassName={styles.productPriceStub} width={"50px"} height={"18px"}/>
                                <div className={styles.buyButtonBlock}>
                                    <Stub borderRadius={"25px"} additionalClassName={styles.buyButtonButtonStub}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.certifiedStubBlock}>
                        <Stub additionalClassName={styles.productDescriptionStub} width={"100%"} height={"36px"}/>
                    </div>
                </>) : (<>
                    <div className={styles.breadcrumbsBlock}>
                        <Link to={"/"} className={styles.breadcrumbLinkText}>Главная</Link>
                        <span className={styles.breadcrumbSpacerText}>&nbsp;&gt;&nbsp;</span>
                        <Link to={"/catalog"} className={styles.breadcrumbLinkText}>Каталог</Link>
                        <span className={styles.breadcrumbSpacerText}>&nbsp;&gt;&nbsp;</span>
                        <Link to={`/catalog/${item?.categoryId}`}
                              className={styles.breadcrumbLinkText}>{props.categories.items
                            .find(category => category.id === item?.categoryId)?.name}</Link>
                        <span className={styles.breadcrumbSpacerText}>&nbsp;&gt;&nbsp;</span>
                        <span className={styles.activeBreadcrumbText}>{item?.name}</span>
                    </div>
                    <div className={styles.flexBlock}>
                        <div className={styles.productImageBlock} style={{backgroundImage: `url(${item?.imageUrl})`}}/>
                        <div className={styles.productInfoBlock}>
                            <div className={styles.productNameBlock}>{item?.name}</div>
                            <div className={styles.productDescriptionBlock} dangerouslySetInnerHTML={{
                                __html: item?.description ?? ""
                            }}/>
                            <div className={styles.productPriceAndBuyBlock}>
                                <div className={styles.productPriceBlock}>
                                    <Price price={item?.price ?? 0} discount={item?.discount} unit={item?.unit ?? ""}/>
                                </div>
                                <div className={styles.buyButtonBlock}>
                                    <button className={`${styles.buyButtonButton} ${props.cart.items
                                        .find(cartItem => cartItem.item.id === item?.id) ? styles.buyButtonButtonActive :
                                        ""}`} onClick={() => {
                                        if (item) {
                                            props.cart.addToCart({
                                                item,
                                                quantity: 1
                                            });
                                        }
                                    }}>{props.cart.items.find(cartItem => cartItem.item.id === item?.id) ? "Товар выбран" :
                                        "Выбрать товар"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.certifiedStubBlock}>Продукция компании "Русские Продукты" запатентована и
                        сертифицирована.
                        Вся продукция, поставляемая компанией "Русские Продукты", производится согласно ТУ или по ГОСТ.
                    </div>
                </>)}
            </Row>
        </>);
    }}</Observer>);
}
