import styles from "./CatalogPage.module.css";
import React from "react";
import {Row} from "../../utils/row/Row";
import {LoadableList} from "../../../utils/loadable";
import {Category, getItems, getItemsOnSale, ItemsResult, search, ShortItem} from "../../../api/API";
import {Observer, useLocalObservable} from "mobx-react";
import {Stub} from "../../utils/stub/Stub";
import {Price} from "../../utils/price/Price";
import {useHistory} from "react-router-dom";
import {Cart} from "../../../cart/Cart";

type CatalogType = 'all' | 'sales' | 'category' | 'search';

interface CatalogPageProps {
    type: CatalogType;
    categories: LoadableList<Category>;
    categoryId?: string;
    search?: string;
    cart: Cart;
}

const itemsPerPage = 12;

function getLoader(type: CatalogType, categoryId: string | undefined, searchTerm: string | undefined, skip: number, take: number):
    Promise<ItemsResult> {
    switch (type) {
        case "all":
            return getItems(skip, take);
        case "sales":
            return getItemsOnSale(skip, take);
        case "category":
            return getItems(skip, take, categoryId ?? "");
        case "search":
            return search(skip, take, searchTerm ?? "");
    }
}

export function CatalogPage(props: CatalogPageProps) {
    const history = useHistory();
    const state = useLocalObservable(() => ({
        loadingItems: false,
        totalItems: 0,
        items: [] as ShortItem[],
        lastType: 'all' as CatalogType,
        lastCategoryId: undefined as string | undefined,
        lastSearch: undefined as string | undefined
    }));

    if (props.categoryId !== state.lastCategoryId || props.type !== state.lastType || props.search !== state.lastSearch) {
        state.items = [];
        state.totalItems = 0;
        state.loadingItems = false;
    }

    state.lastCategoryId = props.categoryId;
    state.lastType = props.type;
    state.lastSearch = props.search;

    if (props.type === "category") {
        props.categories.load().catch(err => console.error(err));
    }

    if (!state.loadingItems && state.items.length === 0) {
        state.loadingItems = true;
        getLoader(props.type, props.categoryId, props.search, state.items.length, itemsPerPage).then(itemsResult => {
            state.totalItems = itemsResult.totalItems;
            state.items.push(...itemsResult.items);
            state.loadingItems = false;
        }).catch(err => console.error(err));
    }

    return (<Observer>{() => (<>
        <Row>
            {props.type === "category" && props.categories.loading ?
                <Stub width={"30%"} height={"30px"} additionalClassName={styles.categoryTypeRowStub}/> : <div
                    className={styles.categoryTypeRow}>{
                    props.type === "all" ? "Весь каталог" :
                        (props.type === "sales" ? "Скидки" :
                            (props.type === "search" ? `Поиск по "${props.search}"` :
                                props.categories.items.find(category => category.id === props.categoryId)?.name))}</div>}
            {Array.from(new Array(Math.ceil(state.items.length / 4)).keys()).map(i => state.items.slice(i * 4, i * 4 + 4)).map(slice =>
                (<>
                    <div className={styles.contentRow}>{slice.map(item => (
                        <div className={styles.itemCardColumn}>
                            <div className={styles.itemCardImage} style={{backgroundImage: `url("${item.imageUrl}")`}}
                                 onClick={() => history.push(`/product/${item.id}`)}/>
                            <div className={styles.itemCardInfoBlock}>
                                <div className={styles.itemCardNamePriceBlock}>
                                    <div className={styles.itemCardNameText}
                                         onClick={() => history.push(`/product/${item.id}`)}>{item.name}</div>
                                    <div className={styles.itemCardPriceText}>
                                        <Price price={item.price} discount={item.discount} unit={item.unit}/>
                                    </div>
                                </div>
                                <div className={styles.itemCardBuyButtonBlock}>
                                    <button className={`${styles.buyButtonButton} ${
                                        props.cart.items.find(cartItem => cartItem.item.id === item.id) ? styles.activeBuyButton : ""
                                    }`} onClick={() => props.cart.addToCart({
                                        item,
                                        quantity: 1
                                    })}>
                                        {props.cart.items.find(cartItem => cartItem.item.id === item.id) ?
                                            "В корзине" : "В корзину"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}</div>
                    <div className={styles.buttonRow}>{slice.map(item => (
                        <div className={styles.itemCardColumn}>
                            <div className={styles.itemCardInfoBlock}>
                                <div className={styles.buttonRowBuyButtonBlock}>
                                    <button className={`${styles.buyButtonButton} ${
                                        props.cart.items.find(cartItem => cartItem.item.id === item.id) ? styles.activeBuyButton : ""
                                    }`} onClick={() => props.cart.addToCart({
                                        item,
                                        quantity: 1
                                    })}>
                                        {props.cart.items.find(cartItem => cartItem.item.id === item.id) ?
                                            "В корзине" : "В корзину"}
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}</div>
                </>))}
            {state.loadingItems ? (Array.from(new Array(Math.ceil(itemsPerPage / 4)).keys()).map(() =>
                (<div className={styles.contentRow}>{Array.from(new Array(4).keys()).map(() =>
                    (<div className={styles.itemCardColumn}>
                        <Stub width={"100%"} height={"300px"}/>
                    </div>))}</div>))) : (<></>)}
            {state.loadingItems || state.totalItems <= state.items.length ? (<></>) : (
                <div className={styles.loadMoreRow}><span className={styles.loadMoreText} onClick={() => {
                    state.loadingItems = true;
                    getLoader(props.type, props.categoryId, props.search, state.items.length, itemsPerPage).then(itemsResult => {
                        state.totalItems = itemsResult.totalItems;
                        state.items.push(...itemsResult.items);
                        state.loadingItems = false;
                    }).catch(err => console.error(err));
                }}>Показать больше</span></div>)}
            <div className={styles.footerMarginBlock}/>
        </Row>
    </>)}</Observer>);
}
