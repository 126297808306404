import { ShortItem } from '../../../api/API';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { TDocumentDefinitions } from 'pdfmake/interfaces';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

interface toPfdAutoTableProps {
  items: ShortItem[];
}
export default function toPfdTable({ items }: toPfdAutoTableProps) {

  const bodyItems = items.map((it, i) => (
    [
      i + 1,
      it.sku,
      it.name,
      it.discount
        ? {
          text: [
            { text: `${Math.round(it.price - (it.discount / 100 * it.price)) / 100} RUB${it.unit ? `/${it.unit}` : ''} `, color: 'red' },
            { text: `${it.price / 100} RUB${it.unit ? `/${it.unit}` : ''}`, decoration: 'lineThrough' }
          ]
        }
        : `${it.price / 100} RUB${it.unit ? `/${it.unit}` : ''}`,
    ]
  ));

  const docDefinition: TDocumentDefinitions = {
    content: [
      {
        style: 'tableExample',
        table: {
          headerRows: 1,
          widths: [ 'auto', 'auto', '*', 'auto' ],
          body: [
            [
              { text: '№', style: 'tableHeader' },
              { text: 'Код', style: 'tableHeader' },
              { text: 'Полное наименование', style: 'tableHeader' },
              { text: 'Цена', style: 'tableHeader' },
            ],
            ...bodyItems,
          ]
        }
      },
    ],
    pageSize: 'A4',
    pageOrientation: 'landscape',
    styles: {
      tableExample: {
        margin: [0, 5, 0, 15],
      },
      tableHeader: {
        bold: true,
        fontSize: 13,
        color: 'black'
      }
    },
  };

  pdfMake.createPdf(docDefinition).download(`Prices_${new Date().toDateString()}.pdf`);
}
