import styles from './Header.module.css';
import {Row} from "../../utils/row/Row";
import React, {useEffect, useRef, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {Observer, useLocalObservable} from 'mobx-react';
import {Cart} from "../../../cart/Cart";
import {LoadableList} from "../../../utils/loadable";
import {Category, getAllItems, mailerUrl} from "../../../api/API";
import {phone} from "../../../constants";
import axios from "axios";
import downloadIcon from './download.png';
import toPdfTable from '../../utils/pdf/toPdfTable';

interface HeaderProps {
    onShoppingCartClicked: () => void;
    cart: Cart;
    categories: LoadableList<Category>;
    currentCategory: string | null | undefined;
}

const EMAIL_REGEX = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
const PHONE_REGEX = /^\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/;

export function Header(props: HeaderProps) {
    const history = useHistory();
    const location = useLocation();
    const state = useLocalObservable(() => ({
        searchText: "",
        dropdownOpened: false,
        callFormShown: false,
        sentModalShown: false,
        formTopicText: "",
        formPhoneText: "",
        formPhoneErrored: false,
        formEmailText: "",
        formEmailErrored: false,
        formDescriptionText: "",
        formDescriptionErrored: false
    }));
    const [exporting, setExporting] = useState<boolean>(false);

    const dropdownButtonRef = useRef(null);

    useEffect(() => {
        document.addEventListener('click', (event) => {
            if (event.target !== dropdownButtonRef.current) {
                state.dropdownOpened = false;
            }
        });
    });

    props.categories.load().catch(err => console.error(err));

    const goToPage = (page: string) => () => history.push(page);

    const downloadPdfPrices = (): void => {
        setExporting(true);
        getAllItems(0,100000)
          .then((v) => { toPdfTable({ items: v.items }); })
          .catch(console.log)
          .finally(() => setExporting(false));
    };

    return (<Observer>{() => (<header className={styles.header}>
        <div className={styles.workTimeBlock}>
            <Row>
                <a target="_blank"
                   href="https://www.facebook.com/%D0%A0%D1%83%D1%81%D1%81%D0%BA%D0%B8%D0%B5-
                   %D0%9F%D1%80%D0%BE%D0%B4%D1%83%D0%BA%D1%82%D1%8B-103530784996098">
                    <div className={styles.fbIcon} />
                </a>
                <div className={styles.flexBlock}>
                    <div>
                        <span className={styles.textBlock}>{phone}</span>
                    </div>
                    <div className={styles.flexFillerBlock}/>
                    <div>
                        <span className={styles.textBlock}>Время работы: 9:00 - 21:00</span>
                    </div>
                </div>
            </Row>
        </div>
        <div className={styles.logoSearchBarBlock}>
            <Row>
                <div className={styles.flexBlock}>
                    <div className={styles.logoBlock} onClick={() => history.push("/")}/>
                    <div className={styles.searchBarBlock}>
                        <input type={"text"} placeholder={"Введите название продукта..."}
                               className={styles.searchBarInput} value={state.searchText}
                               onChange={event => state.searchText = event.target.value}
                               onKeyPress={(key) => {
                                   if (key.key === "Enter") {
                                       history.push(`/search/${encodeURIComponent(state.searchText)}`);
                                   }
                               }}/>
                    </div>
                    <div className={styles.contactButtonBlock}>
                        <button className={styles.contactButtonButton}
                                onClick={() => state.callFormShown = true}>Связаться
                        </button>
                    </div>
                    <div className={styles.cartButtonBlock} onClick={() => props.onShoppingCartClicked()}>
                        <div className={styles.productCountIndicatorBlock}>{props.cart.items.length}</div>
                    </div>
                </div>
            </Row>
        </div>
        <div className={styles.menuBlock}>
            <Row>
                <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'row', flexWrap: 'wrap'}}>
                    <div className={styles.menuFlexBlock}>
                        <div
                            className={`${styles.menuButtonBlock} ${state.dropdownOpened
                                ? styles.pressedMenuButtonBlock : ""} ${location.pathname.startsWith("/catalog/")
                                ? styles.activeMenuButtonBlock : ""}`}
                            onClick={() => {
                                state.dropdownOpened = true;
                            }}
                            ref={dropdownButtonRef}
                        >
                            Каталог
                        </div>
                        <div
                            className={`${styles.menuButtonBlock} ${location.pathname === "/about"
                                ? styles.activeMenuButtonBlock : ""}`} onClick={goToPage("/about")}>
                            О компании
                        </div>
                        <div
                            className={`${styles.menuButtonBlock} ${location.pathname === "/price"
                                ? styles.activeMenuButtonBlock : ""}`} onClick={goToPage("/price")}>
                            Прайс-лист
                        </div>
                        <div className={`${styles.menuButtonBlock} ${location.pathname === "/logistics"
                            ? styles.activeMenuButtonBlock : ""}`} onClick={goToPage("/logistics")}>
                            Логистика
                        </div>
                        <div className={`${styles.menuButtonBlock} ${location.pathname === "/news"
                            ? styles.activeMenuButtonBlock : ""}`} onClick={goToPage("/news")}>
                            Новости
                        </div>
                        <div className={`${styles.menuButtonBlock} ${location.pathname === "/contacts"
                            ? styles.activeMenuButtonBlock : ""}`} onClick={goToPage("/contacts")}>
                            Контакты
                        </div>
                        <div className={`${styles.menuButtonBlock} ${location.pathname === "/sales"
                            ? styles.activeMenuButtonBlock : ""}`} onClick={goToPage("/sales")}>
                            <span className={styles.redText}>Скидки %</span>
                        </div>
                    </div>
                    <div className={styles.menuButtonBlock} onClick={downloadPdfPrices}>
                        <div className={styles.flexedBox}>
                            <span style={{marginRight: 7}}>{exporting ? 'Загрузка цен...' : 'Наши цены'}</span>
                            <img src={downloadIcon} alt="download" width={20} height={20}/>
                        </div>
                    </div>
                </div>
                <div className={styles.dropdownContainer} hidden={!state.dropdownOpened}>
                    <div className={styles.dropdownButtons}>
                        <div className={styles.dropdownMenuContainer}>
                            <div className={`${styles.categoryButton} ${props.currentCategory === null ?
                                styles.activeCategoryButton : ""}`} onClick={() => history.push("/catalog")}>Весь
                                каталог
                            </div>
                            {props.categories.loading ? (<></>) : props.categories.items.map(item =>
                                (<div key={item.id}
                                      className={`${styles.categoryButton} ${item.id === props.currentCategory ?
                                          styles.activeCategoryButton : ""}`}
                                      onClick={() => history.push(`/catalog/${item.id}`)}>{item.name}</div>))}
                        </div>
                    </div>
                </div>
            </Row>
        </div>
        <div className={styles.requestFormContainer} hidden={!state.callFormShown}>
            <div className={styles.requestForm}>
                <div className={styles.requestFormBody}>
                    <div className={styles.flexBlock}>
                        <div className={styles.flexFillerBlock}/>
                        <div className={styles.closeButton} onClick={() => state.callFormShown = false}/>
                    </div>
                    <div className={styles.inputGroup}>
                        <div className={styles.inputGroupTitle}>
                            <label htmlFor={"subject"}>
                                Тема:
                            </label>
                        </div>
                        <input type={"text"} id={"subject"} className={styles.input}
                               value={state.formTopicText}
                               onChange={event => state.formTopicText = event.target.value}/>
                    </div>
                    <div className={styles.inputGroup}>
                        <div className={styles.inputGroupTitle}>
                            <label htmlFor={"phone"}>
                                Телефон:
                            </label>
                        </div>
                        <input type={"text"} id={"phone"}
                               className={`${styles.input} ${state.formPhoneErrored ? styles.inputBad : ""}`}
                               value={state.formPhoneText}
                               onChange={event => {
                                   const phoneText = state.formPhoneText = event.target.value;
                                   state.formPhoneErrored = phoneText.length > 0 && !phoneText.match(PHONE_REGEX);
                               }}/>
                        <div className={styles.inputErrorText}
                             style={{display: state.formPhoneErrored ? undefined : "none"}}>
                            Неправильный формат телефона
                        </div>
                    </div>
                    <div className={styles.inputGroup}>
                        <div className={styles.inputGroupTitle}>
                            <label htmlFor={"email"}>
                                E-Mail: *
                            </label>
                        </div>
                        <input type={"email"} id={"email"}
                               className={`${styles.input} ${state.formEmailErrored ? styles.inputBad : ""}`}
                               value={state.formEmailText}
                               onChange={event => {
                                   const emailText = state.formEmailText = event.target.value;
                                   state.formEmailErrored = emailText.length === 0 || !emailText.match(EMAIL_REGEX);
                               }}/>
                        <div className={styles.inputErrorText}
                             style={{display: state.formEmailErrored ? undefined : "none"}}>
                            Неправильный формат E-Mail
                        </div>
                    </div>
                    <div className={styles.inputGroup}>
                        <div className={styles.inputGroupTitle}>
                            <label htmlFor={"description"}>
                                Описание: *
                            </label>
                        </div>
                        <textarea id={"description"}
                                  className={`${styles.input} ${state.formDescriptionErrored ? styles.inputBad : ""}`}
                                  value={state.formDescriptionText}
                                  onChange={event => {
                                      const descriptionText = state.formDescriptionText = event.target.value;
                                      state.formDescriptionErrored = descriptionText.length === 0;
                                  }}/>
                        <div className={styles.inputErrorText}
                             style={{display: state.formDescriptionErrored ? undefined : "none"}}>
                            Описание не заполнено
                        </div>
                    </div>
                    <div className={styles.flexBlock}>
                        <div className={styles.flexFillerBlock}/>
                        <button className={styles.contactModalButton}
                                disabled={state.formEmailErrored || state.formPhoneErrored ||
                                state.formDescriptionErrored} onClick={() => {
                            const topicText = state.formTopicText;
                            const phoneText = state.formPhoneText;
                            state.formPhoneErrored = phoneText.length > 0 && !phoneText.match(PHONE_REGEX);
                            const emailText = state.formEmailText;
                            state.formEmailErrored = emailText.length === 0 || !emailText.match(EMAIL_REGEX);
                            const descriptionText = state.formDescriptionText;
                            state.formDescriptionErrored = descriptionText.length === 0;

                            if (state.formEmailErrored || state.formPhoneErrored ||
                                state.formDescriptionErrored) {
                                return;
                            }

                            axios.post(mailerUrl, {
                                topic: topicText,
                                phone: phoneText,
                                email: emailText,
                                description: descriptionText
                            }).catch(e => console.error(e));

                            state.callFormShown = false;
                            state.sentModalShown = true;
                        }}>Отправить
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div className={styles.requestFormContainer} hidden={!state.sentModalShown}>
            <div className={styles.sentForm}>
                <div className={styles.sentFormBody}>
                    <div className={styles.flexBlock}>
                        <div className={styles.flexFillerBlock}/>
                        <div className={styles.closeButton} onClick={() => state.sentModalShown = false}/>
                    </div>
                    <div className={styles.sentImage}/>
                    <div className={styles.sentText}>
                        Ваше сообщение успешно отправлено
                    </div>
                </div>
            </div>
        </div>
    </header>)}</Observer>)
}
