import styles from "./Stub.module.css";
import React from "react";

interface StubProps {
    width?: string;
    height?: string;
    borderRadius?: string;
    additionalClassName?: string;
}

export function Stub(props: StubProps) {
    return (<div style={{width: props.width, height: props.height, borderRadius: props.borderRadius}}
                 className={`${styles.stub} ${props.additionalClassName ?? ""}`}/>);
}
