import React from 'react';
import { Row } from '../../utils/row/Row';
import { phone } from '../../../constants';
import { priceList } from './config';
import styles from './PricePage.module.css';

interface Arr {
  name: string
  country: string
  typePack: string
  availability: string
  price: string
}

export function PricePage() {
  return (
    <>
    <div className={styles.priceHeader}>
      <img src="./logo128.png" alt="LOGO" />
      <span>ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ</span>
      <br/>
      <span>«Русские Продукты»</span>
      <br/>
      <span>ОГРН: 1207700367654, ИНН/КПП: 7734437615/773401001</span>
    </div>
      <Row>
        <div className={styles.tableBlock}>
          <div className={styles.title}>Прайс-лист на сырье:</div>
          <table cellPadding={5}>
          <thead>
            <tr>
              <th>Наименование товара</th>
              <th>Страна-производитель</th>
              <th>Тип упаковки</th>
              <th>Наличие</th>
              <th>Цена, за кг.</th>
            </tr>
            </thead>
            
            {priceList.map(({title, price}: {title: string, price: Arr[]}) => (
              <tbody key={title}>
                <tr>
                  <th colSpan={5}>{title}</th>
                </tr>
              {price.map(info => (
              <tr key={info.name}>
                <td>{info.name}</td>
                <td>{info.country}</td>
                <td>{info.typePack}</td>
                <td>{info.availability}</td>
                <td>{info.price}</td>
              </tr>
                ))}
              </tbody>
            ))}

            
          </table>
          <div className={styles.contact}>
            Все цены на условиях предоплаты и самовывоза со склада МО, обсуждаются и требуют
            дополнительного подтверждения.
            <div>Кеслер Юрий Михайлович</div>
            <div className={styles.tel}>Тел {phone}</div>
            <div className={styles.email}>
             <span>e-mail</span>
              <a href="mailto:vlad@htmlbook.ru">info@russproduct.ru</a>
            </div>
          </div>
        </div>
      </Row>
    </>
  );
}
