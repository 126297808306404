import {Row} from "../../utils/row/Row";
import styles from "./Footer.module.css";
import React from "react";
import {useHistory} from "react-router-dom";
import {secondAddress, address, phone} from "../../../constants";
import {getAllItems} from "../../../api/API";
import toPdfTable from '../../utils/pdf/toPdfTable';

export function Footer() {
    const history = useHistory();

    const downloadPdfPrices = (): void => {
        getAllItems(0,100000)
            .then((v) => { toPdfTable({ items: v.items }); })
            .catch(console.log);
    };

    return (<footer className={styles.footer}>
        <Row>
            <div className={styles.flexBlock}>
                <div className={styles.twoLinksFlexBlock}>
                    <div className={styles.twoLinksFlexInnerBlock}>
                        <div className={styles.twoLinksInnerBlock}>
                            <span className={styles.link} onClick={() => history.push("/catalog")}>Каталог</span>
                        </div>
                        <div className={styles.twoLinksInnerBlock}>
                            <span className={styles.link} onClick={() => history.push("/sales")}>Скидки</span>
                        </div>
                    </div>
                </div>
                <div className={styles.twoLinksFlexBlock}>
                    <div className={styles.twoLinksFlexInnerBlock}>
                        <div className={styles.twoLinksInnerBlock}>
                            <span className={styles.link} onClick={() => history.push("/about")}>О компании</span>
                        </div>
                        <div className={styles.twoLinksInnerBlock}>
                            <span className={styles.link} onClick={() => history.push("/price")}>Прайс-лист</span>
                        </div>
                    </div>
                </div>
                <div className={styles.twoLinksFlexBlock}>
                    <div className={styles.twoLinksFlexInnerBlock}>
                        <div className={styles.twoLinksInnerBlock}>
                            <span className={styles.link} onClick={() => history.push("/policy")}>Политика</span>
                        </div>
                        <div className={styles.twoLinksInnerBlock}>
                            <span className={styles.link} onClick={() => history.push("/logistics")}>Логистика</span>
                        </div>
                    </div>
                </div>
                <div className={styles.twoLinksFlexBlock}>
                    <div className={styles.twoLinksFlexInnerBlock}>
                        {/* <div className={styles.twoLinksInnerBlock}>
                            <span className={styles.link} onClick={() => history.push("/news")}>Новости</span>
                        </div> */}
                        <div className={styles.twoLinksInnerBlock}>
                            <span className={styles.link} onClick={() => history.push("/contacts")}>Контакты</span>
                        </div>
                        <div className={styles.twoLinksInnerBlock}>
                            <span className={styles.link} onClick={() => downloadPdfPrices()}>Наши цены</span>
                        </div>
                    </div>
                </div>
                <div className={styles.flexBlockFiller}/>
                <div className={styles.contactsBlock}>
                    <div className={styles.phoneText}>
                        {phone}
                    </div>
                    <div className={styles.workTimeText}>
                        Время работы: 9:00 - 21:00
                    </div>
                    <div className={styles.addressText}>
                        {address}
                        <br/>
                        {secondAddress}
                    </div>
                </div>
            </div>
            <div className={styles.copyrightText}>
                2021 &copy; Русские Продукты. Все права защищены
            </div>
        </Row>
    </footer>);
}
