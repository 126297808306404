import React from "react";
import {Row} from "../../utils/row/Row";
import styles from "./AboutPage.module.css"
import icon1 from "./icons/icon1.png";
import icon2 from "./icons/icon2.png";
import icon3 from "./icons/icon3.png";
import icon4 from "./icons/icon4.png";
import icon5 from "./icons/icon5.png";
import icon6 from "./icons/icon6.png";
import icon7 from "./icons/icon7.png";
import icon8 from "./icons/icon8.png";

export function AboutPage() {
    return (<>
        <Row>
            <div className={styles.aboutTextBlock}>
                <b>Компания «Русские Продукты»</b> — одна из крупных участников московского рынка оптовых поставок охлаждённого
                мяса и мяса глубокой заморозки. В числе наших клиентов крупные мясоперерабатывающие комбинаты, ведущие
                торговые сети и супермаркеты столицы и регионов, различные, в том числе лечебные и образовательные учреждения. 
                <p/>
                Наш ассортимент насчитывает ряд позиций мясопродуктов из говядины, баранины и мяса птицы. также мы реализуем
                говяжьи и куриные и субпродукты. Поставщики нашей компании - ведущие производители мясной продукции России,
                и республики Беларусь. 
                <p/>
                Полный перечень и описание продукции вы можете найти на тематических страницах нашего
                сайта. Мы стараемся держать на нашем складе большой ассортиментный перечень замороженного мяса от разных
                поставщиков, что позволяет нашим покупателям выбрать наиболее подходящую по цене и качеству продукцию.
                Весь предлагаемый нами ассортимент - мясная продукция исключительного качества, прошедшая строжайший
                контроль ветеринарного департамента и полностью соответствующая действующим на территории России нормам
                и требованиям.
                <p/>
                Политика работы компании - максимум внимания каждому клиенту: индивидуальный подход в
                вопросах цен, процессов хранения, отгрузки и доставки. Наша компания обладает собственным мобильным
                грузовым автопарком и, в случае необходимости, мы готовы взять на себя доставку продукции по
                заявленному адресу.
                <p/>
                Мы сотрудничаем с надежными транспортными компаниями, отправляющими
                замороженное мясо оптом в Центральный и дальние регионы. Также мы доставляем наши товары
                собственным автотранспортом фирмы в города, удаленность. до 200 км от Москвы. Пристальное
                внимание в компании уделяется правильному обеспечению сопроводительной и ветеринарной
                документацией.
                <p/>
                Мы приглашаем к взаимовыгодному сотрудничеству заинтересованные организации.
                Уточнить условия и договориться о поставках можно, предварительно пообщавшись с нашим отделом закупок.
                
            </div>
            <div className={styles.titleText}>Мы гарантируем:</div>
            <div className={styles.blocksContainer}>
                <div className={styles.block}>
                    <div className={styles.iconBlock}>
                        <img src={icon1} alt={"Палец вверх"}/>
                    </div>
                    <div className={styles.warrantyTitleText}>
                        Качество
                    </div>
                    <div className={styles.warrantyText}>
                        Поставки нашей продукции напрямую от производителя по лучшим ценам и без задержек
                    </div>
                </div>
                <div className={styles.blockInverse}>
                    <div className={styles.iconBlock}>
                        <img src={icon2} alt={"Сертификат"}/>
                    </div>
                    <div className={styles.warrantyTitleText}>
                        Надёжность
                    </div>
                    <div className={styles.warrantyText}>
                        Имеется вся сопутствующая документация и сертификаты на товары
                    </div>
                </div>
                <div className={styles.block}>
                    <div className={styles.iconBlock}>
                        <img src={icon3} alt={"Лучший выбор"}/>
                    </div>
                    <div className={styles.warrantyTitleText}>
                        Ассортимент
                    </div>
                    <div className={styles.warrantyText}>
                        Широкий ассортимент одна из визитных карточек нашей организации
                    </div>
                </div>
                <div className={styles.blockInverse}>
                    <div className={styles.iconBlock}>
                        <img src={icon4} alt={"Галочка"}/>
                    </div>
                    <div className={styles.warrantyTitleText}>
                        Гарантии
                    </div>
                    <div className={styles.warrantyText}>
                        Многолетний опыт на рынке оптовых поставок. Выбирая нас, вы выбираете качество и надежность
                    </div>
                </div>
            </div>
            <div className={`${styles.blocksContainer} ${styles.secondBlocksContainer}`}>
                <div className={styles.blockInverse}>
                    <div className={styles.iconBlock}>
                        <img src={icon5} alt={"Договор"}/>
                    </div>
                    <div className={styles.warrantyTitleText}>
                        Доступность
                    </div>
                    <div className={styles.warrantyText}>
                        Мы работаем с прямыми проверенными поставщиками, и это дает возможность держать низкие цены
                    </div>
                </div>
                <div className={styles.block}>
                    <div className={styles.iconBlock}>
                        <img src={icon6} alt={"Здоровье"}/>
                    </div>
                    <div className={styles.warrantyTitleText}>
                        Удобство
                    </div>
                    <div className={styles.warrantyText}>
                        Простая и удобная программа лояльности для покупателей
                    </div>
                </div>
                <div className={styles.blockInverse}>
                    <div className={styles.iconBlock}>
                        <img src={icon7} alt={"Поддержка"}/>
                    </div>
                    <div className={styles.warrantyTitleText}>
                        Сервис
                    </div>
                    <div className={styles.warrantyText}>
                        Отменный сервис и пятизвездочное обслуживание
                    </div>
                </div>
                <div className={styles.block}>
                    <div className={styles.iconBlock}>
                        <img src={icon8} alt={"Взаимоотношения"}/>
                    </div>
                    <div className={styles.warrantyTitleText}>
                        Профессионализм
                    </div>
                    <div className={styles.warrantyText}>
                        Профессионализм и гибкость наших менеджеров
                    </div>
                </div>
            </div>
        </Row>
    </>);
}
