import styles from "./LogisticsPage.module.css";
import icon1 from "./icons/icon1.png";
import icon2 from "./icons/icon2.png";
import icon3 from "./icons/icon3.png";
import icon4 from "./icons/icon4.png";
import React from "react";
import {Row} from "../../utils/row/Row";

export function LogisticsPage() {
    return (<>
        <Row>
            <div className={styles.contentBlock}>
                <div className={styles.titleBar}>
                    <span className={styles.titleText}>Дорогие покупатели!</span>
                </div>
                <div className={styles.contentRow}>
                    <div className={styles.warrantyCardColumn}>
                        <div className={styles.iconBlock}>
                            <img className={styles.icon} src={icon1} alt={"7"}/>
                        </div>
                        <div className={styles.warrantyTitleText}>
                            7 дней в неделю
                        </div>
                    </div>
                    <div className={styles.warrantyCardColumn}>
                        <div className={styles.iconBlock}>
                            <img className={styles.icon} src={icon2} alt={"Россия"}/>
                        </div>
                        <div className={styles.warrantyTitleText}>
                            С 8:00 до 24:00
                        </div>
                    </div>
                    <div className={styles.warrantyCardColumn}>
                        <div className={styles.iconBlock}>
                            <img className={styles.icon} src={icon3} alt={"Часы"}/>
                        </div>
                        <div className={styles.warrantyTitleText}>
                            По всей России
                        </div>
                    </div>
                    <div className={styles.warrantyCardColumn}>
                        <div className={styles.iconBlock}>
                            <img className={styles.icon} src={icon4} alt={"Грузовик"}/>
                        </div>
                        <div className={styles.warrantyTitleText}>
                            Бесплатно по Москве
                        </div>
                    </div>
                </div>
                <br/>
                Доставка по Москве и ближайшему Подмосковью.<br/><br/>
                Доставка заказов осуществляется 7 дней в неделю!<br/><br/>
                Комплектация заказа в течение 1-2 дней после принятия заявки!<br/><br/>
                Стандартное время доставки по Москве с 8:00 до 24:00. По желанию клиента доставку можем осуществить на ранее утро с 6:00 и позднее время до 2:00.<br/><br/>
                Минимальный промежуток по времени 3 часа. Просьба учитывать состояние и загруженность дорог г. Москвы.<br/><br/>
                Погрузка и разгрузка товара осуществляется квалифицированными грузчиками нашей компании.<br/><br/>
                Доставка
                <ul className={styles.deliveryList}>
                    <li>по Москве (в пределах МКАД) – <b>бесплатно</b>, при этом сумма заказа не может быть менее 50 000 рублей</li>
                    <li>по Подмосковью – (от МКАД) – 15 руб. за 1 километр в обе стороны</li>
                    <li>доставка при заказе менее 20 000 рублей, стоимость доставки 700 рублей в пределах МКАД</li>
                </ul>
                Все подробные условия можно уточнить у менеджеров отдела доставки.<br/><br/>
                Мы осуществляем доставку в регионы через партнерские Транспортные Компании, но не несем ответственность за доставку, порчу продукции при транспортировке. <br/><br/>
                Просьба учитывать эту информацию при заказе.<br/><br/>
                Сумма заказа на регионы России, не должна быть ниже 100 000 рублей. Доставка до ТК бесплатная.<br/><br/>
                Стоимость перевозки Транспортной Компанией оплачивается в соответствии с тарифами грузоперевозчика.<br/><br/>
            </div>
        </Row>
    </>);
}
