import styles from "./ItemCard.module.css";
import React from "react";
import {ShortItem} from "../../../../../api/API";
import {Price} from "../../../../utils/price/Price";
import { useHistory } from "react-router-dom";

interface ItemCardProps {
    item: ShortItem;
}

export function ItemCard(props: ItemCardProps) {
    const history = useHistory();

    return (<div>
        <div style={{backgroundImage: `url("${props.item.imageUrl}")`}} className={styles.saleCardImageBlock}
            onClick={() => history.push(`/product/${props.item.id}`)}/>
        <div className={styles.saleCardNameText} onClick={() => history.push(`/product/${props.item.id}`)}>{props.item.name}</div>
        <div className={styles.saleCardPriceText}>
            <Price price={props.item.price} discount={props.item.discount} unit={props.item.unit}/>
        </div>
    </div>);
}
