import {Observer, useLocalObservable} from "mobx-react";
import React from "react";
import {Row} from "../../utils/row/Row";
import styles from "./PostPage.module.css";
import {getNewsPost, NewsPost} from "../../../api/API";
import {Stub} from "../../utils/stub/Stub";

interface PostPageParams {
    postId: string;
}

export function PostPage(params: PostPageParams) {
    const state = useLocalObservable(() => ({
        post: undefined as (NewsPost | undefined),
        isLoading: false
    }));

    if (!state.isLoading) {
        state.isLoading = true;
        getNewsPost(params.postId).then(post => state.post = post);
    }

    return <Observer>{() => <Row>
        {state.post ?
            <div className={styles.internalRow}>
                <div className={styles.titleRow}>{state.post.title}</div>
                <div className={styles.dateRow}><span className={styles.bold}>Дата: </span>
                    {new Date(state.post.timestamp).toLocaleDateString("ru-RU")}</div>
                <div className={styles.postImage} style={{backgroundImage: `url('${state.post.imageUrl}')`}}/>
                <div className={styles.text}>
                    {state.post.text.split("\n").map(value => <p>{value}</p>)}
                </div>
                <div className={styles.sourceRow}><span className={styles.bold}>Источник: </span>
                    <a href={state.post.sourceUrl} target={"_blank"} rel={"noreferrer"} className={styles.link}>
                        {state.post.sourceUrl}
                    </a></div>
            </div> : <div className={styles.internalRow}>
                <div className={styles.titleRow}><Stub height={"25px"} width={"60%"}/></div>
                <div className={styles.dateRow}><Stub height={"18px"} width={"20%"}/></div>
                <Stub width={"100%"} height={"400px"}/>
                <div className={styles.text}>
                    <Stub width={"100%"} height={"700px"}/>
                </div>
                <div className={styles.sourceRow}><Stub height={"18px"} width={"20%"}/></div>
            </div>}
    </Row>}</Observer>;
}