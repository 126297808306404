import styles from "./ContactsPage.module.css";
import icon1 from "./icons/icon1.png";
import icon2 from "./icons/icon2.png";
import icon3 from "./icons/icon3.png";
import icon4 from "./icons/icon4.png";
import {Row} from "../../utils/row/Row";
import React from "react";
import {YMaps, Map, Placemark} from "react-yandex-maps";
import {address, phone} from "../../../constants";

export function ContactsPage() {
    return (<>
        <Row>
            <div className={styles.contentBlock}>
                <div className={styles.contentRow}>
                    <div className={styles.warrantyCardColumn}>
                        <div className={styles.iconBlock}>
                            <img className={styles.icon} src={icon1} alt={"Метка"}/>
                        </div>
                        <div className={styles.warrantyTitleText}>
                            {address}
                        </div>
                    </div>
                    <div className={styles.warrantyCardColumn}>
                        <div className={styles.iconBlock}>
                            <img className={styles.icon} src={icon2} alt={"Телефон"}/>
                        </div>
                        <div className={styles.warrantyTitleText}>
                            {phone}
                        </div>
                    </div>
                    <div className={styles.warrantyCardColumn}>
                        <div className={styles.iconBlock}>
                            <img className={styles.icon} src={icon3} alt={"Письмо"}/>
                        </div>
                        <div className={styles.warrantyTitleText}>
                            info@russproduct.ru
                        </div>
                    </div>
                    <div className={styles.warrantyCardColumn}>
                        <div className={styles.iconBlock}>
                            <img className={styles.icon} src={icon4} alt={"Часы"}/>
                        </div>
                        <div className={styles.warrantyTitleText}>
                            9:00 - 21:00
                        </div>
                    </div>
                </div>
                <div className={styles.titleBar}>
                    <span className={styles.titleText}>Мы на карте:</span>
                </div>
                <YMaps>
                    <Map className={styles.mapRow} defaultState={{center: [55.777383, 37.504456], zoom: 17}}>
                        <Placemark geometry={[55.777383, 37.504456]} />
                    </Map>
                </YMaps>
                <div className={styles.preFooterFillerBlock}/>
            </div>
        </Row>
    </>);
}
