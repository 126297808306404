import React, {MouseEvent} from 'react';
import styles from "./ShoppingCart.module.css";
import {Cart} from "../../../cart/Cart";
import {Price} from "../../utils/price/Price";
import {Observer} from "mobx-react";
import {address, phone} from "../../../constants";
import downloadIcon from '../header/download.png';
import toPdfTable from '../../utils/pdf/toPdfTable';

interface ShoppingCartProps {
    onClose: () => void;
    visible: boolean;
    cart: Cart;
}

export function ShoppingCart(props: ShoppingCartProps) {

    const onDownload = (e: MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        toPdfTable({ items: props.cart.items.map(item => item.item) });
    };

    return (
        <Observer>
            {() => (
                <>
                    <div className={styles.shoppingCartBlock}
                         style={{right: props.visible ? "0" : "min(-515px, -30vw)"}}>
                        <div className={styles.topHeaderBlock}>
                            <div className={styles.closeButtonBlock} onClick={() => props.onClose()}/>
                            <div className={styles.flexFillerBlock}/>
                            <div className={styles.selectedItemsText}>Выбранные товары</div>
                        </div>
                        <div className={styles.selectedItemsBlock}>
                            {props.cart.items.map((item, index) => (
                                <div className={styles.selectedItemBlock} key={index}>
                                    <div className={styles.itemImageBlock}
                                         style={{backgroundImage: `url("${item.item.imageUrl}")`}}/>
                                    <div className={styles.itemNameBlock}>{item.item.name}</div>
                                    <div className={styles.flexFillerBlock}/>
                                    <div className={styles.itemQuantityChangeBlock}>
                                        <div className={styles.minusButton} onClick={() => {
                                            if (item.quantity > 1) {
                                                item.quantity--;
                                                props.cart.save();
                                            }
                                        }}/>
                                        <div className={styles.quantityText}>{item.quantity}</div>
                                        <div className={styles.plusButton} onClick={() => {
                                            item.quantity++;
                                            props.cart.save();
                                        }}/>
                                    </div>
                                    <div className={styles.itemPriceBlock}><Price
                                        price={item.item.price * item.quantity} discount={item.item.discount}
                                        showDiscount={false}/></div>
                                    <div className={styles.removeButton}
                                         onClick={() => props.cart.removeFromCart(index)}/>
                                </div>))}
                        </div>
                        <div className={styles.clearBlock}>
                            <div className={styles.totalText}>Сумма: <span className={styles.priceText}>
                                <Price price={props.cart.items.reduce((previousValue, currentValue) => previousValue +
                                    currentValue.quantity *
                                    (currentValue.item.price - currentValue.item.price * (currentValue.item.discount ?? 0) / 100), 0)}
                                       showDiscount={false}/>
                            </span></div>
                            <div className={styles.flexFillerBlock}/>
                            {props.cart.items.length > 0 ?
                                <div className={styles.clearText} onClick={() => props.cart.clear()}>Очистить
                                    корзину</div> : <></>}
                        </div>
                        <div className={styles.infoBlock}>
                            <div className={styles.topInfoTextBlock}>Адрес приобритения товаров:</div>
                            <div className={styles.bottomInfoTextBlock}>{address}</div>
                        </div>
                        <div className={styles.infoBlock}>
                            <div className={styles.topInfoTextBlock}>Время работы:</div>
                            <div className={styles.bottomInfoTextBlock}>с 9:00 до 21:00</div>
                        </div>
                        <div className={styles.infoBlock}>
                            <div className={styles.flexedBox}>
                                <div>
                                    <div className={styles.topInfoTextBlock}>Телефон для заказа:</div>
                                    <div className={styles.phoneTextBlock}>{phone}</div>
                                </div>
                                <a
                                    className={props.cart.items.length ? styles.downloadLink : styles.downloadLink__disabled}
                                    href={'/#'}
                                    onClick={onDownload}
                                >
                                    <div className={styles.flexedBox}>
                                        <span style={{marginRight: 7}}>Скачать список</span>
                                        <img src={downloadIcon} alt="скачать список" width={20} height={20}/>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </Observer>
    );
}
