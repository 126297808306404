import {computed, makeObservable, observable, runInAction} from "mobx";

export interface LoadableCollection {
    reload(): Promise<void>;
    load(): Promise<void>;
    readonly loading: boolean;
}

export class LoadableList<T> implements LoadableCollection {
    @observable private _items: T[] | undefined;
    @observable private _loading: boolean = false;

    constructor(private loader: () => Promise<T[]>, private dependencies: LoadableCollection[] = []) {
        makeObservable(this);
    }

    async reload(): Promise<void> {
        await Promise.all([Promise.all(this.dependencies.map(dependency => dependency.reload())),
            this.reloadSelf()]);
    }

    private async reloadSelf(): Promise<void> {
        if (this._loading) {
            return;
        }
        runInAction(() => {
            this._loading = true;
        });
        try {
            this._items = await this.loader();
        } catch (e) {
            this._items = [];
            throw e;
        } finally {
            runInAction(() => {
                this._loading = false;
            });
        }
    }

    async load(): Promise<void> {
        const dependenciesLoadPromise = Promise.all(this.dependencies.map(dependency => dependency.load()));
        if (this._loading || this._items !== undefined) {
            await dependenciesLoadPromise;
            return;
        }
        await Promise.all([dependenciesLoadPromise, this.reloadSelf()]);
    }

    @computed get loading(): boolean {
        return this._loading || this.dependencies.reduce((total: boolean, current: LoadableCollection) =>
            total || current.loading, false);
    }

    @computed get items(): T[] {
        return this.loading ? [] : (this._items ?? []);
    }
}


export class LoadableMap<TValue> {
    @observable private _items: Record<string, TValue> = {};
    @observable private _loading: Record<string, boolean> = {};

    constructor(private loader: (key: string) => Promise<TValue>, private dependencies: LoadableCollection[] = []) {
        makeObservable(this);
    }

    private async reloadSelf(key: string): Promise<void> {
        if (this._loading[key]) {
            return;
        }
        this._loading[key] = true;
        try {
            this._items[key] = await this.loader(key);
        } catch (e) {
            delete this._items[key];
            throw e;
        } finally {
            delete this._loading[key];
        }
    }

    async load(key: string): Promise<void> {
        const dependenciesLoadPromise = Promise.all(this.dependencies.map(dependency => dependency.load()));
        if (this._loading[key] || this._items[key] !== undefined) {
            await dependenciesLoadPromise;
            return;
        }
        await Promise.all([dependenciesLoadPromise, this.reloadSelf(key)]);
    }

    isLoading(key: string): boolean {
        return this._loading[key] || this.dependencies.reduce((total: boolean, current: LoadableCollection) =>
            total || current.loading, false);
    }

    getItem(key: string): TValue | undefined {
        return this.isLoading(key) ? undefined : this._items[key];
    }
}
