import {gql, GraphQLClient} from "graphql-request/dist";
import AsyncLock from "async-lock";

export {}

// getCategories
// getItems(categoryId?)
// getItemsOnSale
// getItem(itemId)
// addToCart
// getCart

export interface Category {
    id: string,
    name: string,
    imageUrl?: string
}

export interface ShortItem {
    id: string,
    name: string,
    imageUrl: string,
    price: number,
    discount?: number,
    unit: string,
    description: string,
    sku: string
}

export interface ShortNewsPost {
    id: string,
    imageUrl: string,
    title: string,
    previewText: string
}

export interface NewsPost {
    id: string,
    imageUrl: string,
    title: string,
    text: string,
    timestamp: string,
    sourceUrl: string
}

export interface Item extends ShortItem {
    description: string,
    categoryId: string
}

const apiUrl = process.env.REACT_APP_CMS_URL ? `${process.env.REACT_APP_CMS_URL}/shop-api` :
    "https://cms.russproduct.ru/shop-api";

export const mailerUrl = `${process.env.REACT_APP_CMS_URL}/send-mail`;

const saleFacetValueId = 1;

const client = new GraphQLClient(apiUrl);

export async function getCategories(): Promise<Category[]> {
    const result = await client.request(gql`
        query GetCollections($options: CollectionListOptions) {
            collections(options: $options) {
                items {
                    id
                    name
                    featuredAsset {
                        source
                    }
                }
            }
        }
    `);
    return result.collections.items.map((collection: any) => ({
        id: collection.id,
        name: collection.name,
        imageUrl: collection.featuredAsset?.source
    }));
}

export interface ItemsResult {
    items: ShortItem[];
    totalItems: number;
}

export async function getItems(skip: number, take: number, categoryId?: string): Promise<ItemsResult> {
    const result = await client.request(gql`
        query GetItemsOnSale($input: SearchInput!) {
            search(input: $input) {
                items {
                    productId
                }
                totalItems
            }
        }
    `, {
        input: {
            collectionId: categoryId,
            take,
            skip
        }
    });
    return {
        items: await Promise.all(result.search.items.map((item: any) => getShortItem(item.productId))),
        totalItems: result.search.totalItems
    };
}

export interface NewsResult {
    items: ShortNewsPost[],
    totalItems: number
}

export async function getNews(skip: number, take: number): Promise<NewsResult> {
    return (await client.request(gql`
        query GetNews($skip: Int!, $take: Int!) {
            news(skip: $skip, take: $take) {
                items {
                    id
                    imageUrl
                    title
                    previewText
                }
                totalItems
            }
        }
    `, {
        skip,
        take
    })).news;
}

export async function getNewsPost(id: string): Promise<NewsPost> {
    return (await client.request(gql`
        query GetNewsPost($id: ID!) {
            newsPost(id: $id) {
                id
                imageUrl
                title
                text
                sourceUrl
                timestamp
            }
        }
    `, {
        id
    })).newsPost;
}

export async function getAllItems(skip: number, take: number): Promise<ItemsResult> {
    const result = await client.request(gql`
        query GetItemsOnSale($options: ProductListOptions) {
            products(options: $options) {
                items {
                    id
                    name
                    variants {
                        price
                        sku
                    }
                    customFields {
                        discount
                        unit
                    }
                    featuredAsset {
                        source
                    }
                },
                totalItems
            }
        }
    `, {
        options: {
            take,
            skip
        }
    });
    return {
        items: result.products.items.map((p: any) => ({
            id: p.id,
            sku: p.variants.length ? p.variants[0].sku : '',
            name: p.name,
            unit: p.customFields.unit,
            discount: p.customFields.discount,
            price: p.variants.length ? p.variants[0].price : -1,
            // imageUrl: p.featuredAsset.source,
        })),
        totalItems: result.products.totalItems
    };
}

export async function getItemsOnSale(skip: number, take: number): Promise<ItemsResult> {
    const result = await client.request(gql`
        query GetItemsOnSale($input: SearchInput!) {
            search(input: $input) {
                items {
                    productId
                },
                totalItems
            }
        }
    `, {
        input: {
            facetValueIds: [saleFacetValueId],
            take,
            skip
        }
    });
    return {
        items: await Promise.all(result.search.items.map((item: any) => getShortItem(item.productId))),
        totalItems: result.search.totalItems
    };
}

const shortItemCacheLock = new AsyncLock();
const shortItemCache: Record<string, ShortItem> = {};

export async function getShortItem(itemId: string): Promise<ShortItem> {
    if (shortItemCache[itemId]) {
        return shortItemCache[itemId];
    }
    await shortItemCacheLock.acquire(itemId, async () => {
        if (shortItemCache[itemId]) {
            return;
        }
        const result = await client.request(gql`
            query GetItem($id: ID, $slug: String) {
                product(id: $id, slug: $slug) {
                    name
                    description
                    featuredAsset {
                        source
                    }
                    variants {
                        price
                        sku
                    }
                    customFields {
                        discount
                        unit
                    }
                }
            }
        `, {
            id: itemId
        });
        shortItemCache[itemId] = {
            name: result.product.name,
            imageUrl: result.product.featuredAsset.source,
            id: itemId,
            price: result.product.variants[0].price,
            discount: result.product.customFields.discount === null ? undefined : result.product.customFields.discount,
            unit: result.product.customFields.unit,
            description: result.product.description,
            sku: result.product.variants[0].sku,
        };
    });
    return shortItemCache[itemId];
}

const itemCacheLock = new AsyncLock();
const itemCache: Record<string, Item> = {};

export async function getItem(itemId: string): Promise<Item> {
    if (itemCache[itemId]) {
        return itemCache[itemId];
    }
    await itemCacheLock.acquire(itemId, async () => {
        if (itemCache[itemId]) {
            return;
        }
        const result = await client.request(gql`
            query GetItem($id: ID, $slug: String) {
                product(id: $id, slug: $slug) {
                    name
                    description
                    featuredAsset {
                        source
                    }
                    variants {
                        price
                        sku
                    }
                    collections {
                        id
                    }
                    customFields {
                        discount
                        unit
                    }
                }
            }
        `, {
            id: itemId
        });
        itemCache[itemId] = {
            description: result.product.description,
            name: result.product.name,
            categoryId: result.product.collections[0].id,
            imageUrl: result.product.featuredAsset.source,
            id: itemId,
            price: result.product.variants[0].price,
            discount: result.product.customFields.discount === null ? undefined : result.product.customFields.discount,
            unit: result.product.customFields.unit,
            sku: result.product.variants[0].sku,
        };
    });
    return itemCache[itemId];
}

export async function search(skip: number, take: number, search: string): Promise<ItemsResult> {
    const result = await client.request(gql`
        query GetItemsOnSale($input: SearchInput!) {
            search(input: $input) {
                items {
                    productId
                },
                totalItems
            }
        }
    `, {
        input: {
            term: search,
            take,
            skip
        }
    });
    return {
        items: await Promise.all(result.search.items.map((item: any) => getShortItem(item.productId))),
        totalItems: result.search.totalItems
    };
}
