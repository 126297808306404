import {Header} from "./header/Header";
import {Footer} from "./footer/Footer";
import React from "react";
import styles from "./Page.module.css";
import {ShoppingCart} from "./shopping_cart/ShoppingCart";
import {Observer, useLocalObservable} from "mobx-react";
import {Cart} from "../../cart/Cart";
import {LoadableList} from "../../utils/loadable";
import {Category} from "../../api/API";

interface PageProps {
    cart: Cart;
    categories: LoadableList<Category>;
    currentCategory: string | null | undefined;
}

export function Page(props: React.PropsWithChildren<PageProps>) {
    const state = useLocalObservable(() => ({
        shoppingCartOpened: false
    }));

    return (<Observer>{() => (<>
        <div className={styles.bodyBlock}>
            <div className={styles.headerBlock}>
                <Header categories={props.categories} currentCategory={props.currentCategory}
                        onShoppingCartClicked={() => state.shoppingCartOpened = true} cart={props.cart}/>
            </div>
            <div className={styles.contentBlock}>
                <main>
                    {props.children}
                </main>
            </div>
            <div className={styles.footerBlock}>
                <Footer/>
            </div>
            <ShoppingCart visible={state.shoppingCartOpened} cart={props.cart}
                          onClose={() => state.shoppingCartOpened = false}/>
        </div>
    </>)}</Observer>);
}
