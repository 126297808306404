import {action, computed, makeObservable, observable} from "mobx";
import {ShortItem} from "../api/API";

export interface CartItem {
    item: ShortItem;
    quantity: number;
}

export class Cart {
    @observable private items_: CartItem[] = [];

    constructor() {
        makeObservable(this);
        if (localStorage.getItem("cart") != null) {
            this.items_ = JSON.parse(localStorage.getItem("cart") ?? "[]");
            for (const item of this.items_) {
                if (!item.item || !item.quantity) {
                    this.items_ = [];
                    return;
                }
            }
        }
    }

    @computed get items(): CartItem[] {
        return this.items_;
    }

    @action addToCart(item: CartItem): void {
        const existingItem = this.items_.find(itemInCart => itemInCart.item.id === item.item.id);
        if (existingItem) {
            return;
        }
        this.items_.push(item);
        localStorage.setItem("cart", JSON.stringify(this.items_));
    }

    @action removeFromCart(index: number): void {
        this.items_.splice(index, 1);
        localStorage.setItem("cart", JSON.stringify(this.items_));
    }

    @action clear() {
        this.items_ = [];
        localStorage.setItem("cart", JSON.stringify(this.items_));
    }

    save() {
        localStorage.setItem("cart", JSON.stringify(this.items_));
    }
}
