import {Observer, useLocalObservable} from "mobx-react";
import React, {useEffect, ReactNode} from "react";

interface StubCarouselProps {
    images: string[];
    contents: ReactNode[];
    delay: number;
    className: string
}

export function StubCarousel(props: StubCarouselProps) {
    const state = useLocalObservable(() => ({
        currentImage: 0
    }));

    useEffect(() => {
        const timeout = setInterval(() => state.currentImage = (state.currentImage + 1) % props.images.length, props.delay);
        return () => clearInterval(timeout);
    });

    return (<Observer>{() => (<>{props.images.map((image, i) =>
        (<div className={props.className}
              key={image}
              style={{backgroundImage: `url("${image}")`, left: 100 * (i - state.currentImage) + "vw"}}>
            {props.contents[i]}</div>))}</>)}</Observer>)
}
