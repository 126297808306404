import React from 'react';
import './App.css';
import {Observer, useLocalObservable} from 'mobx-react';
import {APIStateStorage} from "./api/APIStateStorage";
import {AppRouter} from "./components/navigation/router/AppRouter";
import {Cart} from "./cart/Cart";

export default function App() {
    const state = useLocalObservable(() => ({
        stateStorage: new APIStateStorage(),
        cart: new Cart()
    }));

    return <Observer>{() => (<AppRouter stateStorage={state.stateStorage} cart={state.cart}/>)}</Observer>;
}
